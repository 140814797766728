import React from "react";
import { useIntl } from "gatsby-plugin-intl"

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"

import Resource1 from "../images/resource1.png";

const Resources = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "site.title" })} />
      <div className="terms-wrapper">
        <h2 className="header">{intl.formatMessage({ id: "resources.body.header" })}</h2>
        <div className="wrapper">
          <div className="resource-wrapper">
            <h3 className="header">{intl.formatMessage({ id: "resources.body.griffier" })}</h3>
            <img src={Resource1} alt={intl.formatMessage({ id: "image.alt.resources" })} />
          </div>
        </div>
      </div>
    </Layout>
  )
};

export default Resources;