import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

function Metadata({ title }) {
    const { site, allFile } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                    }
                }
                allFile(
                    limit: 1
                    filter: {
                        name: { eq: "favicon" }
                        ext: { eq: ".svg" }
                        sourceInstanceName: { eq: "images" }
                        relativeDirectory: { eq: "" }
                    }
                ) {
                    nodes {
                        publicURL
                    }
                }
            }
        `
    )

    return (
        <Helmet
            // Workaround for https://github.com/nfl/react-helmet/issues/315
            defer={false}
            // If template can't be used, use just the given title raw
            defaultTitle={title}
            // titleTemplate={`%s | ${site.siteMetadata.title}`}
            titleTemplate={`%s`}
        >
            <link
                rel="icon"
                // We get a url for this via graphql because we don't want to
                // inline it, which webpack would do for us if we imported it
                // directly
                // Querying publicURL on a file node causes it to be copied
                href={allFile.nodes[0].publicURL}
                type="image/svg+xml"
                sizes="any"
            />
            <meta
                name="viewport"
                content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
            />

            {/* {process.env.GOOGLE_SITE_VERIFICATION_CONTENT && <meta name="google-site-verification" content={process.env.GOOGLE_SITE_VERIFICATION_CONTENT} />} */}

            <title>{title}</title>
        </Helmet>
    )
}

Metadata.defaultProps = {
    description: ``,
}

Metadata.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string.isRequired,
}

export default Metadata