/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React, { useEffect } from "react"
 import PropTypes from "prop-types"
 import { useStaticQuery, graphql } from "gatsby"
 import anime from "animejs"
 
 import Header from "./header"
 import Footer from "./footer"
 import Metadata from "./metadata"
 import "../../scss/main.scss"
 
 const Layout = ({ children }) => {
   const data = useStaticQuery(graphql`
     query SiteTitleQuery {
       site {
         siteMetadata {
           title
         }
       }
     }
   `)
 
   const myRef = React.createRef()
   useEffect(() => {
     anime({
       targets: myRef.current,
       opacity: "1",
       delay: 2000,
       duration: 5000
     })
   })
 
   return (
     <>
       <Metadata title={data.site.siteMetadata.title} />
       <Header siteTitle={data.site.siteMetadata.title} />
       <div>
         <main>{children}</main>
       </div>
       <Footer />
     </>
   )
 }
 
 Layout.propTypes = {
   children: PropTypes.node.isRequired,
 }
 
 export default Layout
 